// @flow
import React, { useState, useEffect } from 'react';
import Input from '../../Input';
import s from '../style.module.scss';

type Props = {
  onChange: Function,
  filledData?: Object,
}

function WorkExperienceSection(props: Props) {
  const { onChange, filledData } = props;

  const [workExperience, setWorkExperience] = useState(filledData);

  useEffect(() => {
    onChange(workExperience);
  }, [workExperience, onChange]);

  return (
    <div className={s.inputWrapper}>
      <Input
        type="text"
        placeholder="Role"
        onChange={(e) => { setWorkExperience({ ...workExperience, role: e.target.value }); }}
        value={workExperience.role}
        isRequired
      />
      <Input
        type="text"
        placeholder="Company Name"
        onChange={(e) => { setWorkExperience({ ...workExperience, companyName: e.target.value }); }}
        value={workExperience.companyName}
        isRequired
      />
      <Input
        type="text"
        placeholder="Country"
        onChange={(e) => { setWorkExperience({ ...workExperience, country: e.target.value }); }}
        value={workExperience.country}
        isRequired
      />
      <Input
        type="text"
        placeholder="City"
        onChange={(e) => { setWorkExperience({ ...workExperience, city: e.target.value }); }}
        value={workExperience.city}
        isRequired
      />
      <Input
        type="date"
        labelContent="Start date"
        onChange={(e) => { setWorkExperience({ ...workExperience, startDate: e.target.value }); }}
        value={workExperience.startDate}
        isRequired
      />
      {!workExperience.currentPosition && (
        <Input
          type="date"
          labelContent="End date"
          onChange={(e) => { setWorkExperience({ ...workExperience, endDate: e.target.value }); }}
          value={workExperience.endDate}
          isRequired
        />
      )}
      <Input
        type="checkbox"
        labelContent="I currently work there"
        onChange={() => {
          setWorkExperience(
            { ...workExperience, currentPosition: !workExperience.currentPosition },
          );
        }}
        checked={workExperience.currentPosition}
        isSwitch
        isRequired
      />
    </div>
  );
}

WorkExperienceSection.defaultProps = {
  filledData: {
    role: '',
    companyName: '',
    country: '',
    city: '',
    startDate: '',
    endDate: '',
    currentPosition: false,
  },
};

export default WorkExperienceSection;
