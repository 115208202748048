import React from 'react';
import SignUpScreen from './component';
import s from '../../styles/modal.module.scss';

function SignUpScreenContainer() {
  return (
    <div className={s.modalContainer}>
      <SignUpScreen onSubmitClick={(e) => { console.log(e); }} />
    </div>
  );
}

export default SignUpScreenContainer;
