export default function fileToBase64(filename, filepath) {
  return new Promise((resolve) => {
    const file = new File([filename], filepath);
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.readAsDataURL(file);
  });
}
