// @flow
import React from 'react';
import s from './style.module.scss';

type TitleProps = {
  content: string,
  level?: number,
  light?: boolean,
}

function Title(props: TitleProps) {
  const { content, level, light } = props;
  const style = light ? { color: '#101018' } : { color: '#ffffff' };

  const LeveledTitle = React.createElement(
    `h${level}`,
    {
      className: `${s.title} ${s.heading} ${s[level]}`,
      style,
    },
    content,
  );

  return LeveledTitle;
}

Title.defaultProps = {
  level: 1,
  light: false,
};

export default Title;
