import React from 'react';
import s from './style.module.scss';

type Props = {
  media: string,
  showOverlay?: boolean,
}

function FullScreenVideoBackground(props: Props) {
  const {
    media,
    showOverlay,
  } = props;

  return (
    <div className={`${s.fullScreenVideoBackground} ${showOverlay && s.showOverlay}`}>
      <video autoPlay muted loop playsInline>
        <source src={media} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

FullScreenVideoBackground.defaultProps = {
  showOverlay: false,
};

export default FullScreenVideoBackground;
