import React, { useState } from 'react';
import Title from '../../Title';
import EducationSection from '../EducationSection';
import Button from '../../Button';
import Header from '../../../containers/Header';
import s from '../style.module.scss';

type Props = {
  onNextClick: Function,
  onBackClick: Function,
  filledData?: Object,
}

function EducationForm(props: Props) {
  const {
    onNextClick,
    onBackClick,
    filledData,
  } = props;
  const [educations, setEducations] = useState(filledData);
  const [educationCount, setEducationCount] = useState(Object.keys(educations).length - 1);

  return (
    <div className={s.formContainer}>
      <Header screenTitle="Tell us about you" />
      <>
        <div className={s.formContainer__wrapper}>
          <Title level={3} content="What did you studied?" />
        </div>
        <EducationSection
          onChange={(edu) => { setEducations({ ...educations, 0: edu }); }}
          filledData={educations[0]}
        />
        {[...Array(educationCount)].map((item, index) => {
          return (
            <EducationSection
              key={`experience-${educationCount + index}`}
              onChange={(edu) => { setEducations({ ...educations, [index + 1]: edu }); }}
              filledData={educations[index + 1]}
            />
          );
        })}
        <button
          type="button"
          onClick={() => { setEducationCount(educationCount + 1); }}
          className={s.formContainer__addOption}
        >
        + add education
        </button>
      </>
      <div className={s.formContainer__wrapper}>
        <Button
          primary
          content="Next step"
          onClick={() => { onNextClick(educations); }}
        />
        <Button content="Previous Step" onClick={onBackClick} transparent />
      </div>
    </div>
  );
}

EducationForm.defaultProps = {
  filledData: {
    0: {
      grade: '',
      schoolName: '',
      country: '',
      city: '',
      graduationDate: '',
    },
  },
};

export default EducationForm;
