import React from 'react';
import s from '../style.module.scss';
import Header from '../../../containers/Header';
import Button from '../../Button';

type Props = {
  onFileUpload: Function,
  onSkip?: Function,
}


function ProfilePictureUpload(props: Props) {
  const { onFileUpload, onSkip } = props;
  return (
    <div className={s.formContainer}>

      <Header screenTitle="Choose or take a profile picture" />

      <div className={s.formContainer__upload}>
        <label
          htmlFor="camera"
          className={s.image}
        >
          <input
            type="file"
            accept="camera"
            className={s.fileUpload}
            onChange={onFileUpload}
            id="camera"
          />
        </label>
        <div className={s.formContainer__wrapper}>
          <Button onClick={onSkip} content="Skip this step" secondary />
        </div>
      </div>
    </div>
  );
}

ProfilePictureUpload.defaultProps = {
  onSkip: () => {},
};

export default ProfilePictureUpload;
