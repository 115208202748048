import React from 'react';
import { Link } from 'react-router-dom';
import s from '../style.module.scss';
import Header from '../../../containers/Header';
import Title from '../../Title';
import Button from '../../Button';

type Props = {
  firstName: string,
  onGoClick: Function,
}

function Registered(props: Props) {
  const {
    firstName,
    onGoClick,
  } = props;
  return (
    <div className={s.formContainer}>
      <Header screenTitle={`Welcome on board ${firstName}`} />
      <div className={s.formContainer__wrapper}>
        <Title
          level={3}
          content="Now that you have registered, you can take a few minutes to fill in your profile and get in touch with thousands of freelancers with the same interests as yours and find relevant offers."
        />
      </div>
      <div className={s.formContainer__wrapper}>
        <Button content="Complete Profile" onClick={onGoClick} primary />
        <Link to="/">
          Thanks, I&apos;ll do it later
        </Link>
      </div>
    </div>
  );
}

export default Registered;
