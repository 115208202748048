// @flow
import React, { useState } from 'react';
import Welcome from '../../components/SignUpSteps/Welcome';
import CreateAccount from '../../components/SignUpSteps/CreateAccount';
import Registered from '../../components/SignUpSteps/Registered';
import ProfilePicture from '../../components/SignUpSteps/ProfilePicture';
import LocationAndLanguages from '../../components/SignUpSteps/LocationAndLanguages';
import QualificationsAndShowReel from '../../components/SignUpSteps/QualificationsAndShowReel';
import EducationForm from '../../components/SignUpSteps/EducationForm';
import WorkExperienceForm from '../../components/SignUpSteps/WorkExperienceForm';
import SocialMedias from '../../components/SignUpSteps/SocialMedias';
import ProfileCompleted from '../../components/SignUpSteps/ProfileCompleted';


type Props = {
  onSubmitClick: Function,
}

function SignUpScreen(props: Props) {
  const { onSubmitClick } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [accountData, setAccountData] = useState(undefined);
  const [profilePicture, setProfilePicture] = useState(undefined);
  const [locationAndLanguages, setLocationAndLanguages] = useState(undefined);
  const [qualificationsAndShowreel, setQualificationsAndShowReel] = useState(undefined);
  const [educations, setEducations] = useState(undefined);
  const [workExperiences, setWorkExperiences] = useState(undefined);
  const [socialMedias, setSocialMedias] = useState(undefined);

  const profile = {
    accountData,
    profilePicture,
    locationAndLanguages,
    qualificationsAndShowreel,
    educations,
    workExperiences,
    socialMedias,
  };

  function submitAccount(data) {
    setCurrentStep(2);
    setAccountData(data);
    // NOTE: this is where the account submission request will occur
  }

  console.log(profile);

  const formOrder = [
    <Welcome
      onSignUpClick={() => setCurrentStep(1)}
    />,
    <CreateAccount
      submitAccount={submitAccount}
    />,
    <Registered
      onGoClick={() => setCurrentStep(3)}
      firstName={accountData !== undefined && accountData.firstName}
    />,
    <ProfilePicture
      onChooseImageClick={(e) => { setCurrentStep(4); setProfilePicture(e); }}
      onSkipStep={() => { setCurrentStep(4); setProfilePicture(undefined); }}
      selectedPicture={profilePicture}
    />,
    <LocationAndLanguages
      onBackClick={() => setCurrentStep(3)}
      onNextClick={(e) => { setCurrentStep(5); setLocationAndLanguages(e); }}
      supportedLanguages={['english', 'french', 'dutch']}
      filledData={locationAndLanguages}
    />,
    <QualificationsAndShowReel
      onBackClick={() => setCurrentStep(4)}
      onNextClick={(e) => {
        if (e !== 'error') {
          setCurrentStep(6);
          setQualificationsAndShowReel(e);
        }
      }}
      filledData={qualificationsAndShowreel}
    />,
    <EducationForm
      onBackClick={() => setCurrentStep(5)}
      onNextClick={(e) => { setCurrentStep(7); setEducations(e); }}
      filledData={educations}
    />,
    <WorkExperienceForm
      onBackClick={() => setCurrentStep(6)}
      onNextClick={(e) => { setCurrentStep(8); setWorkExperiences(e); }}
      filledData={workExperiences}
    />,
    <SocialMedias
      supportedMedias={['facebook', 'twitter', 'instagram', 'vimeo', 'youtube']}
      onBackClick={() => setCurrentStep(7)}
      filledData={socialMedias}
      onValidateClick={(e) => {
        setCurrentStep(9);
        setSocialMedias(e);
        onSubmitClick(profile);
      }}
    />,
    <ProfileCompleted />,
  ];

  return (
    <>
      {formOrder[currentStep]}
    </>
  );
}

export default SignUpScreen;
