import React, { useState } from 'react';
import Title from '../../Title';
import Button from '../../Button';
import Input from '../../Input';
import Header from '../../../containers/Header';
import s from '../style.module.scss';
import jsUcfirst from '../../../utils/jsUcFirst';
import { checkUrl, checkUrlsInObject } from '../../../utils/validation';

type Props = {
  onValidateClick: Function,
  onBackClick: Function,
  supportedMedias: Array<string>,
  filledData?: Object,
}

function SocialMedias(props: Props) {
  const {
    onValidateClick,
    onBackClick,
    supportedMedias,
    filledData,
  } = props;

  const [socialMedias, setSocialMedias] = useState(filledData);
  const [hasError, setHasError] = useState({});

  const [otherCount, setOtherCount] = useState(Object.keys(socialMedias.urls.other).length - 1);

  function handleValidateClick() {
    checkUrlsInObject(hasError, onValidateClick, socialMedias);
  }

  return (
    <div className={s.formContainer}>
      <Header screenTitle="We’re almost done" />
      <div className={s.formContainer__wrapper}>
        <Title level={3} content="In which media can we find you?" />
      </div>
      <div className={s.inputWrapper}>
        {supportedMedias.map((media) => {
          return (
            <Input
              key={media}
              type="text"
              placeholder={jsUcfirst(media)}
              onChange={(e) => {
                setSocialMedias(
                  {
                    ...socialMedias,
                    urls: {
                      ...socialMedias.urls,
                      [media]: e.target.value,
                    },
                  },
                );
                if (e.target.value.length > 0) {
                  setHasError({ ...hasError, [media]: checkUrl(e.target.value) });
                } else {
                  setHasError({ ...hasError, [media]: false });
                }
              }}
              value={socialMedias.urls[media]}
              messageContent={hasError[media] ? 'Invalid URL' : ''}
              error={checkUrl(socialMedias.urls[media])}
            />
          );
        })}
        {[...Array(otherCount)].map((item, index) => {
          return (
            <Input
              type="text"
              placeholder="Media URL"
              onChange={(e) => {
                setSocialMedias(
                  {
                    ...socialMedias,
                    urls: {
                      ...socialMedias.urls,
                      other: { [index]: e.target.value },
                    },
                  },
                );
                if (e.target.value.length > 0) {
                  setHasError({ ...hasError, other: { [index]: checkUrl(e.target.value) } });
                } else {
                  setHasError({ ...hasError, other: { [index]: false } });
                }
              }}
              key={`experience-${otherCount + index}`}
              value={socialMedias.urls.other[index]}
              messageContent={
                hasError.other !== undefined
                && hasError.other[index] ? 'Invalid URL' : ''
              }
              error={checkUrl(socialMedias.urls.other[index])}
            />
          );
        })}
        <button
          type="button"
          onClick={() => { setOtherCount(otherCount + 1); }}
          className={s.formContainer__addOption}
        >
        + add other
        </button>
      </div>
      <div className={s.formContainer__wrapper}>
        <Button content="Validate profile" onClick={() => handleValidateClick()} primary />
        <Button content="Previous step" onClick={onBackClick} transparent />
      </div>
    </div>
  );
}

SocialMedias.defaultProps = {
  filledData: {
    urls: {
      other: {
        0: '',
      },
    },
  },
};


export default SocialMedias;
