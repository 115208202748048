// @flow
import React from 'react';
import s from './style.module.scss';

type TextInputProps = {
  labelContent?: string,
  placeholder?: string,
  id: string,
  value?: string,
  isRequired?: boolean,
  onChange?: Function,
  type: string,
  isSwitch?: boolean,
  messageContent?: string,
  error?: boolean,
  className?: string,
  checked?: boolean,
}

function Input(props: TextInputProps) {
  const {
    labelContent,
    placeholder,
    id,
    value,
    isRequired,
    onChange,
    type,
    isSwitch,
    messageContent,
    error,
    className,
    checked,
  } = props;

  const input = type === 'textarea' ? (
    <textarea
      className={`${s.input} ${s[type]} ${className}`}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      required={isRequired}
      id={id}
      type={type}
    />
  ) : (
    <input
      className={`${s.input} ${s[type]} ${className}`}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      required={isRequired}
      id={id}
      type={type}
      checked={checked}
    />
  );

  const message = (
    <small className={`${s.message} ${error && s.error}`}>
      {messageContent}
    </small>
  );

  if (labelContent !== '') {
    return (
      // NOTE: label is conditionnal label does not have a specific id
      // eslint-disable-next-line jsx-a11y/label-has-for
      <label
        htmlFor={id}
        className={`${s.label} ${s[type]} ${isSwitch && s.switch}`}
      >
        {labelContent}
        {input}
        {messageContent !== '' && message }
      </label>
    );
  }
  return (
    <>
      {input}
      {messageContent !== '' && message}
    </>
  );
}

Input.defaultProps = {
  labelContent: '',
  placeholder: '',
  value: '',
  isRequired: false,
  onChange: () => {},
  isSwitch: false,
  messageContent: '',
  error: false,
  className: '',
  checked: false,
};

export default Input;
