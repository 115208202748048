import React, { useState } from 'react';
import Title from '../../Title';
import Button from '../../Button';
import WorkExperienceSection from '../WorkExperienceSection';
import Header from '../../../containers/Header';
import s from '../style.module.scss';

type Props = {
  onNextClick: Function,
  onBackClick: Function,
  filledData?: Object,
}

function WorkExperienceForm(props: Props) {
  const { onNextClick, onBackClick, filledData } = props;
  const [workExperiences, setWorkExperiences] = useState(filledData);
  const [experienceCount, setExperienceCount] = useState(Object.keys(workExperiences).length - 1);

  return (
    <div className={s.formContainer}>
      <Header screenTitle="Tell us about you" />
      <>
        <div className={s.formContainer__wrapper}>
          <Title level={3} content="What are your work experiences? " />
        </div>
        <WorkExperienceSection
          onChange={(exp) => { setWorkExperiences({ ...workExperiences, 0: exp }); }}
          filledData={workExperiences[0]}
        />
        {[...Array(experienceCount)].map((item, index) => {
          return (
            <WorkExperienceSection
              onChange={(exp) => { setWorkExperiences({ ...workExperiences, [index + 1]: exp }); }}
              key={`experience-${experienceCount + index}`}
              filledData={workExperiences[index + 1]}
            />
          );
        })}
        <button
          type="button"
          onClick={() => { setExperienceCount(experienceCount + 1); }}
          className={s.formContainer__addOption}
        >
        + add work experience
        </button>
      </>
      <div className={s.formContainer__wrapper}>
        <Button
          content="Next step"
          onClick={() => { onNextClick(workExperiences); }}
          primary
        />
        <Button content="Previous Step" onClick={onBackClick} transparent />
      </div>
    </div>

  );
}

WorkExperienceForm.defaultProps = {
  filledData: {
    0: {
      role: '',
      companyName: '',
      country: '',
      city: '',
      startDate: '',
      endDate: '',
      currentPosition: false,
    },
  },
};

export default WorkExperienceForm;
