// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar-icon.svg';
import { ReactComponent as ListIcon } from '../../assets/images/list-icon.svg';
import s from './style.module.scss';

function BarButton({ isDashboard } : {isDashboard?: boolean}) {
  return (
    <Link
      to={isDashboard ? '/calendar' : '/'}
      className={s.headerIconLink}
    >
      {isDashboard ? <CalendarIcon /> : <ListIcon />}
    </Link>
  );
}

BarButton.defaultProps = {
  isDashboard: false,
};

export default BarButton;
