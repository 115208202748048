// @flow
import React from 'react';
import BarButton from '../../components/BarButton';
import Title from '../../components/Title';
import s from './style.module.scss';

type HeaderProps = {
  isHome?: boolean,
  screenTitle: string,
  isDashboard?: boolean,
}

function Header(props: HeaderProps) {
  const {
    isHome,
    screenTitle,
    isDashboard,
  } = props;
  return (
    <div className={s.header}>
      <Title content={screenTitle} />
      {isHome && <BarButton currentScreen={screenTitle} className="_t_barButton" isDashboard={isDashboard} />}
    </div>
  );
}

Header.defaultProps = {
  isHome: false,
  isDashboard: false,
};

export default Header;
