import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as UnderdogLogo } from '../../../assets/images/underdog-logo-big.svg';
import s from '../style.module.scss';


function ProfileCompleted() {
  return (
    <div className={s.ProfileCompletedWrapper}>
      <UnderdogLogo />
      <Link
        to="/search"
        className={s.ProfileCompletedWrapper__link}
      >
        Get started!
        {' '}
        <span role="img" aria-label="Rocket">🚀</span>
      </Link>
    </div>
  );
}

export default ProfileCompleted;
