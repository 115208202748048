import React from 'react';
import { Link } from 'react-router-dom';
import s from '../style.module.scss';
import Header from '../../../containers/Header';
import Title from '../../Title';
import Button from '../../Button';
import welcomeScreenVideo from '../../../assets/videos/sign-up-screen.mp4';
import FullScreenVideoBackground from '../../FullScreenVideoBackground';

type Props = {
  onSignUpClick: Function,
}

function Welcome(props: Props) {
  const {
    onSignUpClick,
  } = props;

  return (
    <div className={s.welcomeScreenContainer}>
      <FullScreenVideoBackground media={welcomeScreenVideo} showOverlay />
      <div className={s.welcomeScreenContainer__wrapper}>
        <Header screenTitle="Hi there fellow Underdog! 👋" />
        <div className={s.welcomeScreenContainer__wrapper__content}>
          <Title
            level={3}
            content="Let’s take a moment to sign up!"
          />
          <p className={s.welcomeScreenContainer__wrapper__content__text}>
            Lorem ipsum dolor sit amet, epicurei maluisset eu eum,
            no nostrud antiopam accommodare mei. Ad dicam melius delicata qui,
            fabulas constituam dissentiunt cu ius, mel rationibus delicatissimi
            mediocritatem id. Est ea utinam patrioque, mea an malorum periculis.
            Facete patrioque expetendis ad duo, te sit duis veniam.
            Homero quaeque mediocritatem an vim.
          </p>
        </div>
        <div className={s.welcomeScreenContainer__wrapper__buttons}>
          <Button
            content="Sure! Let's do it!"
            onClick={onSignUpClick}
            primary
          />
          <Link
            to="/signIn"
          >
            I already have an account
          </Link>
          <Link
            to="/"
          >
            I&apos;d rather do it later
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
