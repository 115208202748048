import React, { useState, useEffect } from 'react';
import Input from '../../Input';
import s from '../style.module.scss';

type Props = {
  onChange: Function,
  filledData?: Object,
}

function EducationSection(props: Props) {
  const { onChange, filledData } = props;

  const [education, setEducation] = useState(filledData);

  useEffect(() => {
    onChange(education);
  }, [education, onChange]);

  return (
    <div className={s.inputWrapper}>
      <Input
        type="text"
        placeholder="Level of studies"
        onChange={(e) => { setEducation({ ...education, grade: e.target.value }); }}
        value={education.grade}
        isRequired
      />
      <Input
        type="text"
        placeholder="School Name"
        onChange={(e) => { setEducation({ ...education, schoolName: e.target.value }); }}
        value={education.schoolName}
        isRequired
      />
      <Input
        type="text"
        placeholder="Country"
        onChange={(e) => { setEducation({ ...education, country: e.target.value }); }}
        value={education.country}
        isRequired
      />
      <Input
        type="text"
        placeholder="City"
        onChange={(e) => { setEducation({ ...education, city: e.target.value }); }}
        value={education.city}
        isRequired
      />
      <Input
        type="date"
        labelContent="Graduation date"
        onChange={(e) => { setEducation({ ...education, graduationDate: e.target.value }); }}
        value={education.graduationDate}
        isRequired
      />
    </div>
  );
}

EducationSection.defaultProps = {
  filledData: {
    grade: '',
    schoolName: '',
    country: '',
    city: '',
    graduationDate: '',
  },
};

export default EducationSection;
