// @flow
import React, { useState } from 'react';
import Title from '../../Title';
import Button from '../../Button';
import Input from '../../Input';
import Header from '../../../containers/Header';
import s from '../style.module.scss';
import jsUcfirst from '../../../utils/jsUcFirst';

type Props = {
  onNextClick: Function,
  onBackClick: Function,
  supportedLanguages: Array<string>,
  filledData?: Object,
}

function LocationAndLanguages(props: Props) {
  const {
    onNextClick,
    onBackClick,
    supportedLanguages,
    filledData,
  } = props;

  const [locationAndLanguages, setLocationAndLanguages] = useState(filledData);

  function supportedLanguagesValues(lang) {
    if (locationAndLanguages !== undefined
        && locationAndLanguages.spokenLanguages !== undefined
        && locationAndLanguages.spokenLanguages[lang] !== undefined) {
      return locationAndLanguages.spokenLanguages[lang];
    }
    return false;
  }

  return (
    <div className={s.formContainer}>
      <Header screenTitle="Tell us about you" />
      <div className={s.formContainer__wrapper}>
        <Title level={3} content="Where are you from?" />
      </div>
      <div className={s.inputWrapper}>
        <Input
          type="text"
          placeholder="Country"
          onChange={e => setLocationAndLanguages({
            ...locationAndLanguages,
            country: e.target.value,
          })}
          value={locationAndLanguages.country}
        />
        <Input
          type="text"
          placeholder="City"
          onChange={e => setLocationAndLanguages({
            ...locationAndLanguages,
            city: e.target.value,
          })}
          value={locationAndLanguages.city}
        />
      </div>
      <div className={s.formContainer__wrapper}>
        <Title level={3} content="Which languages do you speak?" />
      </div>
      <div className={s.inputWrapper__languagesWrapper}>

        {supportedLanguages.length > 0 && supportedLanguages.map((lang) => {
          return (
            <Input
              key={lang}
              type="checkbox"
              labelContent={jsUcfirst(lang)}
              id="lang"
              className={s.inputWrapper__languagesWrapper__checkbox}
              onChange={() => {
                if (locationAndLanguages !== undefined
                  && locationAndLanguages.spokenLanguages !== undefined) {
                  setLocationAndLanguages({
                    ...locationAndLanguages,
                    spokenLanguages: {
                      ...locationAndLanguages.spokenLanguages,
                      [lang]: !locationAndLanguages.spokenLanguages[lang],
                    },
                  });
                } else {
                  setLocationAndLanguages({
                    ...locationAndLanguages,
                    spokenLanguages: {
                      [lang]: true,
                    },
                  });
                }
              }}
              checked={supportedLanguagesValues(lang)}
              isSwitch
            />
          );
        })}
        <Input
          type="text"
          placeholder="Add other languages"
          onChange={e => setLocationAndLanguages({
            ...locationAndLanguages,
            spokenLanguages: {
              ...locationAndLanguages.spokenLanguages,
              other: e.target.value.split(','),
            },
          })}
          value={locationAndLanguages.spokenLanguages.other}
          messageContent="All separated by comma."
        />
      </div>

      <div className={s.formContainer__wrapper}>
        <Button content="Next step" onClick={() => onNextClick(locationAndLanguages)} primary />
        <Button content="Previous step" onClick={onBackClick} transparent />
      </div>
    </div>
  );
}

LocationAndLanguages.defaultProps = {
  filledData: {
    country: '',
    city: '',
    spokenLanguages: {
      other: '',
    },
  },
};

export default LocationAndLanguages;
