import React, { useState } from 'react';
import ProfilePictureUpload from '../ProfilePictureUpload';
import ProfilePictureCheck from '../ProfilePictureCheck';
import fileToBase64 from '../../../utils/fileToBase64';

type Props = {
  onChooseImageClick: Function,
  onSkipStep?: Function,
  selectedPicture?: string,
}

function ProfilePicture(props: Props) {
  const {
    onChooseImageClick,
    onSkipStep,
    selectedPicture,
  } = props;

  const [profilePicture, setProfilePicture] = useState(selectedPicture);


  function onFileUpload(e) {
    fileToBase64(e.target.files[0]).then(res => setProfilePicture(res));
  }

  return (
    <>
      {
        profilePicture === undefined
          ? (
            <ProfilePictureUpload
              onFileUpload={(e) => { onFileUpload(e); }}
              onSkip={onSkipStep}
            />
          )
          : (
            <ProfilePictureCheck
              imageSource={profilePicture}
              onChooseImageClick={() => { onChooseImageClick(profilePicture); }}
              onBackClick={() => { setProfilePicture(undefined); }}
            />
          )
      }
    </>
  );
}

ProfilePicture.defaultProps = {
  onSkipStep: () => {},
  selectedPicture: undefined,
};


export default ProfilePicture;
