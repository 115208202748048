import React from 'react';
import s from '../style.module.scss';
import Button from '../../Button';
import Header from '../../../containers/Header';

type Props = {
  imageSource: string,
  onChooseImageClick: Function,
  onBackClick: Function,
}

function ProfilePictureCheck(props: Props) {
  const {
    imageSource,
    onChooseImageClick,
    onBackClick,
  } = props;
  return (
    <div className={s.formContainer}>

      <Header screenTitle="You look nice!" />

      <div className={s.formContainer__imageContainer}>
        <img src={imageSource} alt="" />
      </div>
      <div className={s.formContainer__wrapper}>
        <Button content="Choose that one" onClick={onChooseImageClick} primary />
        <Button content="Retake or reupload" onClick={onBackClick} transparent />
      </div>
    </div>
  );
}

export default ProfilePictureCheck;
