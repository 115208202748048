const rules = {
  email: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  password: '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})',
  // eslint-disable-next-line no-useless-escape
  url: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
};

function checkString(string, reRule) {
  const goodString = new RegExp(reRule);
  if (goodString.test(string)) {
    return false;
  } return true;
}

export function checkPassword(string) {
  return checkString(string, rules.password);
}

export function checkEmail(string) {
  return checkString(string, rules.email);
}

export function checkUrl(string) {
  return checkString(string, rules.url);
}

export function checkUrlsInObject(object, callback, state) {
  if (Object.keys(object).some(val => object[val] === true)) {
    callback('error');
  } else {
    callback(state);
  }
}
