import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../containers/Header';
import Button from '../../Button';
import Title from '../../Title';
import Input from '../../Input';
import s from '../style.module.scss';
import { checkPassword, checkEmail } from '../../../utils/validation';

type Props = {
  submitAccount: Function,
}

function CreateAccount(props: Props) {
  const {
    submitAccount,
  } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const accountData = {
    firstName,
    lastName,
    birthDate,
    email,
    password,
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (!checkEmail(email) && !checkPassword(password)) {
      return submitAccount(accountData);
    } return null;
  }

  return (
    <form onSubmit={(e) => { handleSubmit(e); }}>
      <div className={s.formContainer}>
        <Header screenTitle="Sign-up" />
        <div className={s.formContainer__wrapper}>
          <Title level={3} content="create an account" />
        </div>
        <div className={s.inputWrapper}>
          <Input
            type="text"
            placeholder="First Name"
            onChange={(e) => { setFirstName(e.target.value); }}
            value={firstName}
            isRequired
          />
          <Input
            type="text"
            placeholder="Last Name"
            onChange={(e) => { setLastName(e.target.value); }}
            value={lastName}
            isRequired
          />
        </div>
        <div className={s.inputWrapper}>
          <Input
            type="date"
            labelContent="Birthdate"
            onChange={(e) => { setBirthDate(e.target.value); }}
            value={birthDate}
            isRequired
          />
        </div>
        <div className={s.inputWrapper}>
          <Input
            type="email"
            placeholder="E-mail"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            isRequired
            messageContent={(email.length > 0 && checkEmail(email) ? 'Invalid email' : '')}
            error={email.length > 0 && checkEmail(email)}
          />
          <Input
            type="password"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            isRequired
            messageContent="Must contain at least 8 characters, 1 uppercase and 1 number"
            error={password.length > 0 && checkPassword(password)}
          />
        </div>
        <div className={s.formContainer__wrapper}>
          <Button
            type="submit"
            content="Register"
            primary
          />
          <Link
            to="/signIn"
          >
            I already have an account
          </Link>
        </div>
      </div>
    </form>
  );
}

export default CreateAccount;
