// @flow
import React, { useState } from 'react';
import Title from '../../Title';
import Button from '../../Button';
import Input from '../../Input';
import Header from '../../../containers/Header';
import s from '../style.module.scss';
import { checkUrl, checkUrlsInObject } from '../../../utils/validation';

type Props = {
  onNextClick: Function,
  onBackClick: Function,
  filledData?: Object,
}

function QualificationsAndShowreel(props: Props) {
  const {
    onNextClick,
    onBackClick,
    filledData,
  } = props;


  const [qualificationsAndShowreel, setQualificationsAndShowreel] = useState(filledData);
  const [hasError, setHasError] = useState({});

  const [showreelCount, setShowreelCount] = useState(
    Object.keys(qualificationsAndShowreel.showreelUrls).length - 1,
  );

  function handleNextClick() {
    checkUrlsInObject(hasError, onNextClick, qualificationsAndShowreel);
  }

  return (
    <div className={s.formContainer}>
      <Header screenTitle="Tell us about you" />
      <div className={s.formContainer__wrapper}>
        <Title level={3} content="What are you doing?" />
      </div>
      <div className={s.inputWrapper}>
        <Input
          type="textarea"
          placeholder="About me"
          onChange={e => setQualificationsAndShowreel({
            ...qualificationsAndShowreel,
            bioText: e.target.value,
          })}
          value={qualificationsAndShowreel.bioText}
          isRequired
        />
      </div>
      <div className={s.formContainer__wrapper}>
        <Title level={3} content="What are your skills?" />
      </div>
      <div className={s.inputWrapper}>
        <Input
          type="text"
          placeholder="Skills"
          onChange={e => setQualificationsAndShowreel({
            ...qualificationsAndShowreel,
            skills: e.target.value.split(','),
          })}
          value={qualificationsAndShowreel.skills}
          isRequired
          messageContent="Can be social skills, software, etc...All separated by comma."
        />
      </div>
      <div className={s.formContainer__wrapper}>
        <Title level={3} content="Do you have some work to highlight?" />
      </div>
      <div className={s.inputWrapper}>
        <Input
          type="text"
          placeholder="Image or video URL"
          onChange={(e) => {
            setQualificationsAndShowreel(
              {
                ...qualificationsAndShowreel,
                showreelUrls: {
                  ...qualificationsAndShowreel.showreelUrls,
                  0: e.target.value,
                },
              },
            );
            if (e.target.value.length > 0) {
              setHasError({ ...hasError, 0: checkUrl(e.target.value) });
            } else {
              setHasError({ ...hasError, 0: false });
            }
          }}
          value={qualificationsAndShowreel.showreelUrls[0]}
          messageContent={
            hasError !== undefined && hasError[0] ? 'This is not a valid URL' : ''
          }
          error={checkUrl(qualificationsAndShowreel.showreelUrls[0])}
        />
        {[...Array(showreelCount)].map((item, index) => {
          return (
            <Input
              type="text"
              placeholder="Image or video URL"
              onChange={(e) => {
                setQualificationsAndShowreel(
                  {
                    ...qualificationsAndShowreel,
                    showreelUrls: {
                      ...qualificationsAndShowreel.showreelUrls,
                      [index + 1]: e.target.value,
                    },
                  },
                );
                if (e.target.value.length > 0) {
                  setHasError({ ...hasError, [index + 1]: checkUrl(e.target.value) });
                } else {
                  setHasError({ ...hasError, [index + 1]: false });
                }
              }}
              key={`experience-${showreelCount + index}`}
              value={qualificationsAndShowreel.showreelUrls[index + 1]}
              messageContent={
                hasError !== undefined && hasError[index + 1] ? 'This is not a valid URL' : ''
              }
              error={qualificationsAndShowreel.showreelUrls[index + 1]}
            />
          );
        })}
        <button
          type="button"
          onClick={() => { setShowreelCount(showreelCount + 1); }}
          className={s.formContainer__addOption}
        >
        + add work experience
        </button>
      </div>
      <div className={s.formContainer__wrapper}>
        <Button
          content="Next step"
          onClick={() => handleNextClick()}
          primary
        />
        <Button content="Previous step" onClick={onBackClick} transparent />
      </div>
    </div>
  );
}

QualificationsAndShowreel.defaultProps = {
  filledData: {
    bioText: '',
    skills: [],
    showreelUrls: { 0: '' },
  },
};

export default QualificationsAndShowreel;
