// @flow
import React from 'react';
import s from './style.module.scss';

type ButtonProps = {
  className?: string,
  onClick?: Function,
  content: string,
  leftIcon?: Node,
  rightIcon?: Node,
  primary?: boolean,
  secondary?: boolean,
  transparent?: boolean,
  type?: 'button' | 'submit',
}

function Button(props: ButtonProps) {
  const {
    onClick,
    content,
    className,
    leftIcon,
    rightIcon,
    primary,
    secondary,
    transparent,
    type,
  } = props;

  const classNames = `
    ${s.button} 
    ${primary && `${s.button__primary} _t_main_button`} 
    ${secondary && `${s.button__secondary} _t_secondary_button`} 
    ${transparent && `${s.button__transparent} _t_transparent_button`} 
    ${className}
  `;

  const buttonContent = (
    <>
      {leftIcon !== null && (
        <div className={`${s.icon} ${s.icon__left}`}>
          {leftIcon}
        </div>
      )}
      {content}
      {rightIcon !== null && (
        <div className={`${s.icon} ${s.icon__right}`}>
          {rightIcon}
        </div>
      )}
    </>
  );

  const SubmitButton = React.createElement(
    'button',
    { type: 'submit', className: classNames, onClick },
    buttonContent,
  );
  const StaticButton = React.createElement(
    'button',
    { type: 'button', className: classNames, onClick },
    buttonContent,
  );

  return (
    <>
      {type === 'submit' ? SubmitButton : StaticButton}
    </>
  );
}

Button.defaultProps = {
  onClick: () => {},
  className: '',
  leftIcon: null,
  rightIcon: null,
  primary: false,
  secondary: false,
  transparent: false,
  type: 'button',
};

export default Button;
